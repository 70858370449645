import { AppleOutlined, AppstoreOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, Modal, notification, Select, Table, Tag, Radio, Pagination, Popover, InputNumber, Badge } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { PopupMenu } from "../../component";
import { API_URL } from "../../lib/api";
import { getPosContextmenu } from "../../lib/app";
import { fetchData, getImageApi } from "../../lib/helpers";

import WidgetFormScriptRemote from "./Widget/FormScriptRemote";
import WidgetFormScript from "./Widget/FormScript";
import WidgetFormUpdate from "./Widget/formUpdate";
import QueryString from "qs";
import WidgetDeviceGridView from "./Widget/DeviceGridView";
import UploadUserFb from "../../component/user_fb/upload";
import HomeCreateActionWidget from '../Home/Widget/createAction';

import { connect } from "react-redux";

const DeviceView = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: [],
        statistic: null,
        device_groups: []
    });
    const [pagination, setpagination] = useState({
        limit: 30,
        page: 1,
    });
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleFormScriptRemote, setvisibleFormScriptRemote] = useState({
        visible: false,
        devices: [],
    });
    const [visibleFormScript, setvisibleFormScript] = useState({
        visible: false,
        devices: [],
    });
    const [visibleFormUpdate, setvisibleFormUpdate] = useState({
        visible: false,
        item: null
    });
    const [visibleCreateAction, setvisibleCreateAction] = useState()

    const [dataPopup, setdataPoup] = useState({});
    const [initialValues, setinitialValues] = useState(QueryString.parse(props.location.search.replace(/^\?/gi, "")));
    const [typeViewData, settypeViewData] = useState(localStorage.getItem("typeViewDevice") || "list");
    const [visibleUploadNick, setvisibleUploadNick] = useState(false);

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    const navigate = useHistory()

    const { roleTypeAdmin } = props;

    useEffect(() => {
        return () => {
            setpagination({
                page: 1,
                limit: 20
            })
            refFormFilter.current.resetFields();
        }
    }, [roleTypeAdmin])

    const getListData = async () => {
        try {
            setloading(true);
            let params = {
                ...pagination,
                type: "phone",
                sort_type: typeViewData == "grid" ? "created_time" : ""
            }
            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }
            let response = await fetchData({
                url: roleTypeAdmin ? API_URL.admin.appProfile.index : API_URL.appProfile.index,
                params: params
            });
            setData(response);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: roleTypeAdmin ? API_URL.admin.appProfile.index : API_URL.appProfile.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    useEffect(() => {
        console.log("initialValues", initialValues);
        if (refFormFilter.current) {
            refFormFilter.current.setFieldsValue(initialValues)
        }
    }, [initialValues, refFormFilter])

    useEffect(() => {
        getListData();
    }, [pagination, roleTypeAdmin, typeViewData]);

    const optionBoxName = useMemo(() => {
        return data.device_groups.map((item) => {
            return {
                value: item,
                label: item
            }
        })
    }, [data.device_groups])

    const updateParamLocation = () => {
        props.history.push({
            search: QueryString.stringify(refFormFilter.current.getFieldsValue())
        })
    }

    const handleTypeViewData = (value) => {
        settypeViewData(value);
        localStorage.setItem("typeViewDevice", value)
    }

    const totalNickFromDeviceSelect = useMemo(() => {
        return data.data.filter((item) => selectedRowKeys.includes(item._id)).reduce((a, b) => a + b.user_fbs, 0)
    }, [selectedRowKeys])
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <InputNumber
                    placeholder={`Nhập giá trị`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys([e])}
                    className="mb-3"
                    onPressEnter={(e) => {
                        confirm({ closeDropdown: false });
                    }}
                    addonBefore="<="
                    style={{
                        width: 200
                    }}
                />
                <div>
                    <Button
                        type="primary"
                        size="small"
                        // icon={<SearchOutlined />}
                        style={{ width: 90 }}
                        onClick={() => {
                            confirm({ closeDropdown: false });
                        }}
                        className="mr-2 d-lex align-items-center"
                    >
                        Filter
                    </Button>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => {
                            clearFilters();
                            confirm({ closeDropdown: false });
                        }}
                    >
                        reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </div>
            </div>
        ),
        // filterIcon: (filtered) => (
        //     <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        // ),
        onFilter: (value, record) => {
            return !value || record[dataIndex] <= value
        }
    });

    const selectedRowDeviceIds = useMemo(() => {
        return data.data.filter((item) => selectedRowKeys.includes((item._id))).map((item) => item.profile_id)
    }, [selectedRowKeys])

    const showFormCreateAction = (platform, ids = []) => {
        if (!ids.length) {
            props.notification({
                type: 'error',
                message: 'Tạo chiến dịch',
                description: "Chọn ít nhất 1 tài nguyên để thực hiện hành động này!"
            })
        } else {
            let device_ids = data.data.filter((item) => selectedRowKeys.includes((item._id))).map((item) => item.profile_id)

            setvisibleCreateAction({
                visible: true,
                platform: platform,
                list_uids: device_ids.map((item) => `eid_${item}`),
                device: {
                    device_ids: ids
                }
            })
        }
    }

    return (
        <React.Fragment>
            <WidgetFormScriptRemote
                {...visibleFormScriptRemote}
                onCancel={() => setvisibleFormScriptRemote({ visible: false, devices: [] })}
                roleTypeAdmin={roleTypeAdmin}
                optionBoxName={optionBoxName}
                totalDevice={data.total}
            />
            <WidgetFormScript
                {...visibleFormScript}
                onCancel={() => setvisibleFormScript({ visible: false, devices: [] })}
                onFinish={() => {
                    setvisibleFormScript({ visible: false, devices: [] })
                    getListData();
                }}
                optionBoxName={optionBoxName}
                totalDevice={data.total}
                roleTypeAdmin={roleTypeAdmin}
            />
            <WidgetFormUpdate
                {...visibleFormUpdate}
                onCancel={() => setvisibleFormUpdate({ visible: false, item: null })}
                onFinish={() => {
                    setvisibleFormUpdate({ visible: false, item: null })
                    getListData();
                }}
                roleTypeAdmin={roleTypeAdmin}
            />
            <HomeCreateActionWidget
                {
                ...visibleCreateAction
                }
                hide={() => setvisibleCreateAction()}
            />

            <PopupMenu hide={() => setdataPoup({})} {...dataPopup} menu={<React.Fragment>
                <ul>
                    <li style={{ pointerEvents: "none", background: "#f0f2f5" }}>Thiết bị đã chọn: {selectedRowKeys.length}</li>
                    <li className="border-top" >
                        <span>Tạo hành động</span>
                        <i className="far fa-chevron-right"></i>
                        <ul className="ul-children">
                            {
                                props.Access && props.Access.Platform.data.map((item) => {
                                    return <li onClick={() => {
                                        showFormCreateAction(item, selectedRowKeys)
                                    }}>
                                        {item}
                                    </li>
                                })
                            }
                        </ul>
                    </li>
                    <li
                        onClick={() =>
                            setvisibleFormScriptRemote({
                                visible: true,
                                devices: data.data
                                    .filter((item) => selectedRowKeys.indexOf(item._id) > -1)
                                    .map((item) => item.profile_id),
                            })
                        }
                    >Tạo lệnh remote</li>
                    <li
                        onClick={() =>
                            setvisibleFormScript({
                                visible: true,
                                devices: selectedRowKeys
                            })
                        }
                    >Gán script</li>
                    <li onClick={() => deleteMany(selectedRowKeys)}>Xóa thiết bị</li>
                    {/* <li>Dừng thiết bị</li> */}
                </ul>
            </React.Fragment>} />

            <UploadUserFb
                visible={visibleUploadNick}
                hide={() => setvisibleUploadNick(false)}
                device_ids={selectedRowDeviceIds}
                success={(resutl) => {
                    setvisibleUploadNick(false);
                    setselectedRowKeys([]);
                    Modal.success({
                        title: "Thông báo",
                        content: `Import ${resutl.doc.nUpserted + resutl.doc.nMatched} nick cho ${resutl.device_ids.length} thiết bị thành công`,
                        onOk: () => {
                            props.history.push(`/`)
                        },
                        okText: "Quản lý nick",
                        okCancel: true,
                        cancelText: "Đóng"
                    })
                }}
            />

            <div className="block">
                <div className="block-content block-content-m">
                    <div className="mb-10 d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.total}
                        </Button>
                        <Button type="default" size="small" className="ml-2">
                            Đã chọn: {selectedRowKeys.length}
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                        {
                            !roleTypeAdmin && <Button
                                type="primary"
                                size="small"
                                className="ml-2 d-flex align-items-center"
                                onClick={() => {
                                    setvisibleUploadNick(true)
                                }}
                                disabled={!selectedRowDeviceIds.length}
                            >
                                <i className="fa fa-upload mr-2"></i> Import nick
                            </Button>
                        }
                    </div>
                    {
                        data.statistic && <div className="mb-10">
                            <p className="mb-0">
                                <span className="text-default">Hoạt động: {data.statistic.count_active}</span><br></br>
                                <span className="text-success">Kích hoạt: {data.statistic.count_accept} / {props.userSystem.role?.max_app}</span><br></br>
                                <span className="text-danger">Không Hoạt động: {data.statistic.count_off}</span>
                            </p>
                            <p className="mb-0">Tổng số box: {data.statistic.count_group}</p>
                            <p className="mb-0">Tổng số nick box đang chọn: {totalNickFromDeviceSelect}</p>
                        </div>
                    }
                    <div className="mb-0">
                        <Form
                            ref={refFormFilter}
                            className="row"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = e.keyword || e.device_id || e.user ? 300 : 0;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                    updateParamLocation();
                                }, timeout);
                            }}
                            onReset={() => {
                                getListData();
                                updateParamLocation();
                            }}
                            initialValues={{
                                type: "phone"
                            }}
                        >
                            <Form.Item name="type" noStyle>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="device_id" className="col-md-2">
                                <Input placeholder="device_id1, device_id2..." />
                            </Form.Item>
                            <Form.Item name="device_group" className="col-md-2">
                                {/* <Input placeholder="Box name.."/> */}
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Device group"
                                    options={optionBoxName}
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                />
                            </Form.Item>
                            {
                                roleTypeAdmin && <Form.Item name="user" className="col-md-2">
                                    <Input placeholder="Nhập username || null" />
                                </Form.Item>
                            }
                            <Form.Item name="is_script" className="col-md-2">
                                <Select allowClear placeholder="Trạng thái script">
                                    <Select.Option value={1}>Đã gán script</Select.Option>
                                    <Select.Option value={2}>Chưa gán script</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="d-flex justify-content-end mb-20">
                        <div className="mr-20">
                            <Pagination
                                {...{
                                    total: data.total,
                                    pageSize: pagination.limit,
                                    current: pagination.page,
                                    showSizeChanger: true,
                                    pageSizeOptions: [20, 50, 100, 500, 1000, 2000, 3000, 5000],
                                    onChange: (page, pageSize) => {
                                        setpagination({
                                            limit: pageSize,
                                            page: page,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <Radio.Group className="grid-icon-all" value={typeViewData} onChange={(e) => handleTypeViewData(e.target.value)}>
                            <Radio.Button value={"list"} style={{ fontSize: "20px" }}>
                                <i className="fa fa-list"></i>
                            </Radio.Button>
                            <Radio.Button value={"grid"} className="ml-2" style={{ fontSize: "20px" }}>
                                <div class="grid-icon grid-icon--line">
                                    <span class="layer layer--primary">
                                        <span></span><span></span><span></span>
                                    </span>
                                    <span class="layer layer--secondary">
                                        <span></span><span></span><span></span>
                                    </span>
                                    <span class="layer layer--tertiary">
                                        <span></span><span></span><span></span>
                                    </span>
                                </div>
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    {
                        typeViewData == "list" ?
                            <>
                                <Table
                                    dataSource={data.data}
                                    columns={[
                                        {
                                            title: "#",
                                            render: (value, record, index) => {
                                                return (
                                                    pagination.limit * pagination.page -
                                                    pagination.limit +
                                                    index +
                                                    1
                                                );
                                            },
                                        },
                                        {
                                            title: "Device",
                                            dataIndex: "profile_id",
                                            render: (value, record) => {
                                                return <>
                                                    <p className="mb-2">Id: {value}</p>
                                                    {record.profile_id != record.name && <p className="mb-2">Name: {record.name}</p>}
                                                    {
                                                        record.script && <p className="mb-0 fs-12 text-danger"><i className="far fa-mobile"></i> {record.script && record.script.title}</p>
                                                    }
                                                </>
                                            }
                                        },
                                        {
                                            title: "Screen",
                                            dataIndex: "last_screen",
                                            render: (value, record) => {
                                                return <>
                                                    {
                                                        value && <div
                                                            style={{
                                                                width: "140px",
                                                                height: "180px",
                                                                position: "relative"
                                                            }}
                                                        >
                                                            <Image
                                                                sizes="small"
                                                                src={getImageApi(value)}
                                                                width={140}
                                                                height={180}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }
                                        },
                                        {
                                            title: "Device group",
                                            dataIndex: "device_group",
                                            render: (value, record) => {
                                                return record.log_groups && <>
                                                    <div style={record.log_groups.length > 1 ? { cursor: "pointer" } : {}}>
                                                        <strong>{value}</strong>
                                                        {
                                                            record.log_groups.length > 1 ?
                                                                <div>
                                                                    Log: {
                                                                        record.log_groups.join(", ")
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </>
                                            }
                                        },
                                        {
                                            title: "Số nick",
                                            dataIndex: "user_fbs",
                                            key: "user_fbs",
                                            ...getColumnSearchProps("user_fbs")
                                        },
                                        {
                                            title: "Hoạt động / Get đơn",
                                            dataIndex: "last_time",
                                            render: (value, record) => {
                                                return <>
                                                    {(value ? moment(value).fromNow() : null)} /
                                                    <p className="mb-0">{record.last_time_script_run ? moment(record.last_time_script_run).fromNow() : null}</p>
                                                </>
                                            },
                                        },
                                        {
                                            title: "Thông tin",
                                            dataIndex: "app_version",
                                            render: (value, item) => {
                                                return item.status == 2 ? <>
                                                    <p className="text-danger mb-0">Vui lòng nâng cấp license</p>
                                                </> : <>
                                                    <p className="mb-1">Ver: {item.app_version}</p>
                                                    {
                                                        item.info && <>
                                                            <p className="mb-2">Data type: {item.info.data_type}</p>
                                                            <p className="mb-2">Signal Strength: {item.info.signal_strength}</p>
                                                            <p className="mb-2">Ip Wan: {item.info.ip_wan}</p>
                                                            <p className="mb-0">Ip WiFi: {item.info.ip_wifi}</p>
                                                            {
                                                                item.info.id_rust && <p className="mb-0">Id Rust: {item.info.id_rust}</p>
                                                            }
                                                            {
                                                                item.info.password && <p className="mb-0">Password: {item.info.password}</p>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        },
                                        {
                                            title: "Chủ sở hữu",
                                            dataIndex: "user",
                                            className: !roleTypeAdmin && "d-none",
                                            render: (value) => {
                                                return value && value.user_name
                                            }
                                        },
                                        {
                                            title: "Thao tác",
                                            align: "right",
                                            render: (value, record) => {
                                                let is_script = record.extra_data && record.extra_data.id_script;

                                                return <React.Fragment>
                                                    <div className="d-flex flex-column">
                                                        <Button type="default" size="small" className="mb-2" onClick={() => {
                                                            navigate.push(`${roleTypeAdmin ? '/admin/actions' : '/actions'}?device_id=${record.profile_id}`);
                                                        }}
                                                            disabled={record.status !== 1}
                                                        >
                                                            action
                                                        </Button>
                                                        <Button type="default" size="small" className="mb-2"
                                                            onClick={() => setvisibleFormScript({ visible: true, devices: [record._id], item: record })}
                                                            danger={!is_script ? true : false}
                                                            disabled={record.status !== 1}
                                                        >
                                                            Script {!is_script && "(Trống)"}
                                                        </Button>
                                                        {
                                                            !roleTypeAdmin && <Button type="primary" size="small"
                                                                onClick={() => setvisibleFormUpdate({ visible: true, item: record })}
                                                                className="mb-2"
                                                                disabled={record.status !== 1}
                                                            >
                                                                <i className="far fa-edit"></i>
                                                            </Button>
                                                        }
                                                        <Button type="primary" size="small" danger
                                                            onClick={() => deleteMany([record._id])}
                                                            className="mb-2"
                                                        >
                                                            <i className="far fa-trash"></i>
                                                        </Button>
                                                        <Button className="mb-2" type="primary" size="small"
                                                            onClick={() => {
                                                                props.history.push(`/app/screen/?device_id=${record.profile_id}`)
                                                            }}
                                                            disabled={record.status !== 1}
                                                        >
                                                            Screen
                                                        </Button>
                                                        <Button className="" type="primary" size="small"
                                                            onClick={() => {
                                                                props.history.push(`/?device_ids=${[record.profile_id]}`)
                                                            }}
                                                            disabled={record.status !== 1}
                                                        >
                                                            QL Nick
                                                        </Button>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        }
                                    ]}
                                    rowSelection={{
                                        selectedRowKeys: selectedRowKeys,
                                        onChange: (e) => setselectedRowKeys(e),
                                        getCheckboxProps: (record) => {
                                            return {
                                                disabled: record.status == 2
                                            }
                                        }
                                    }}
                                    rowKey="_id"
                                    pagination={false}
                                    loading={loading}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onContextMenu: e => {
                                                let pos = getPosContextmenu(e);
                                                if (pos.y) {
                                                    pos.y -= 33;
                                                }
                                                setdataPoup({
                                                    ...pos,
                                                    visible: true
                                                })
                                            }
                                        }
                                    }}
                                />
                            </>
                            : <>
                                <WidgetDeviceGridView
                                    data={data.data || []}
                                    loading={loading}
                                    selectedRowKeys={selectedRowKeys}
                                    setselectedRowKeys={setselectedRowKeys}
                                    setvisibleFormScriptRemote={setvisibleFormScriptRemote}
                                    setvisibleFormScript={setvisibleFormScript}
                                    deleteMany={deleteMany}
                                    setvisibleFormUpdate={setvisibleFormUpdate}
                                    roleTypeAdmin={roleTypeAdmin}
                                />
                            </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(DeviceView);

