import { DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, notification, Select, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { API_URL } from "../../../lib/api";
import { copyText } from "../../../lib/app";
import { fetchData } from "../../../lib/helpers";
import { Config, ConfigListAction, StatusSystemData } from "../../../utils/config";
import WidgetForm from "./Widget/form";

import { connect } from "react-redux";

const SystemData = (props) => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setselectedRowKeys] = useState([]);
    const [loading, setloading] = useState(false);
    const [visibleForm, setvisibleForm] = useState({ visible: false });
    const [loadingSave, setloadingSave] = useState(false);

    const refFormFilter = useRef();
    const timeoutSearch = useRef();

    const { Platform } = props.Access;

    const getListData = async () => {
        try {
            setloading(true);
            let params = {};

            if (refFormFilter.current) params = { ...params, ...refFormFilter.current.getFieldsValue() }

            let response = await fetchData({
                url: API_URL.admin.systemData.index,
                params: params
            });
            setData(response.data);
        } catch (err) {
            notification.error({
                message: "Thông báo",
                description: err.message
            });
        } finally {
            setloading(false);
        }
    };

    const deleteMany = async (ids) => {
        Modal.confirm({
            title: 'Bạn có muốn thực hiện thao tác này?',
            icon: <ExclamationCircleOutlined />,
            content: `Xóa ${ids.length} đối tượng`,
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        if (ids.length == 0) throw ("Chọn ít nhất 1 đối tượng!");
                        let response = await fetchData({
                            url: API_URL.admin.systemData.index,
                            method: "delete",
                            body: JSON.stringify({
                                ids: ids
                            })
                        });
                        getListData();

                        setselectedRowKeys([]);

                        notification.success({
                            message: "Thông báo",
                            description: "Thao tác thành công"
                        });
                    } catch (err) {
                        notification.error({
                            message: "Thông báo",
                            description: err.message || err
                        });
                    } finally {

                    }
                    resolve();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() { },
        });

    };

    useEffect(() => {
        getListData();
    }, []);

    return (
        <React.Fragment>

            <WidgetForm 
                {...visibleForm}
                hide={() => setvisibleForm((prev) => ({...prev, visible: false }))}
                onFinish={() => {
                    setvisibleForm((prev) => ({...prev, visible: false }));
                    getListData();
                }}
            />

            <div className="block">
                <div className="block-content block-content-m">
                    <h3 className="block-title">Danh sách tài nguyên</h3>
                    <div className="mt-20 mb-20 d-flex">
                        <Button
                            type="primary"
                            className="d-flex align-items-center mr-4"
                            icon={<PlusOutlined />}
                            onClick={() => setvisibleForm({ visible: true })}
                        >
                            Thêm mới
                        </Button>
                        <Button
                            type="primary"
                            className="d-flex align-items-center"
                            icon={<DeleteOutlined />}
                            onClick={() => deleteMany(selectedRowKeys)}
                            danger
                        >
                            Xóa
                        </Button>
                    </div>
                    <div>
                        <Form
                            ref={refFormFilter}
                            className="row gx-10"
                            onValuesChange={(e) => {
                                if (timeoutSearch.current) clearTimeout(timeoutSearch.current);
                                let timeout = e.key ? 300 : 0;
                                timeoutSearch.current = setTimeout(() => {
                                    getListData();
                                }, timeout);
                            }}
                            onReset={getListData}
                        >
                            <Form.Item name="key" className="col-md-2">
                                <Input placeholder="Nhập loại tài nguyên.." />
                            </Form.Item>
                            <Form.Item className="col-auto">
                                <Button htmlType="reset">Xóa lọc</Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="mb-0 d-flex">
                        <Button type="default" size="small">
                            Tất cả: {data.length}
                        </Button>
                        <Button type="default" size="small" className="ml-2">
                            Đã chọn: {selectedRowKeys.length}
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            className="ml-2 d-flex align-items-center"
                            onClick={getListData}
                        >
                            <i className="si si-reload mr-2"></i> làm mới
                        </Button>
                    </div>
                    <Table
                        dataSource={data}
                        columns={[
                            {
                                title: "Loại",
                                dataIndex: "key"
                            },
                            {
                                title: "Tên",
                                dataIndex: "title",
                            },
                            {
                                title: "Trạng thái",
                                dataIndex: "status",
                                render: (value, record) => {
                                    return StatusSystemData[value] && <Tag color={StatusSystemData[value].color}>{StatusSystemData[value].label}</Tag>
                                }
                            },
                            {
                                title: "Updated time",
                                dataIndex: "updated_time",
                                render: (item) => item ? moment(item).format("HH:mm DD/MM/YYYY") : null
                            },
                            {
                                title: "Thao tác",
                                align: "right",
                                render: (value, record) => {
                                    return <React.Fragment>
                                        <Button type="primary" size="small"
                                            onClick={() => setvisibleForm({ visible: true, item: record })}
                                            className="mb-2"
                                            disabled={record.key?.includes("autophone")}
                                        >
                                            <i className="fa fa-edit"></i>
                                        </Button>
                                        <br></br>
                                        <Button type="primary" size="small" danger
                                            onClick={() => deleteMany([record._id])}
                                        >
                                            <i className="far fa-trash"></i>
                                        </Button>
                                    </React.Fragment>
                                }
                            }
                        ]}
                        rowSelection={{
                            selectedRowKeys: selectedRowKeys,
                            onChange: (e) => setselectedRowKeys(e),
                        }}
                        pagination={{
                            pageSize: 20,
                            position: ["topRight"],
                            size: "small"
                        }}
                        rowKey="_id"
                        loading={loading}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    Access: state.Access
})

export default connect(mapStateToProps)(SystemData);
